import { AudioComponent, Image } from "@global";
import { Maybe, Query } from "@graphql-types";
import { PageProps, graphql } from "gatsby";
import React, { useEffect, useRef, useState } from "react";

import { Component } from "@components";
import { Container } from "@util/standard";
import { PageContext } from "@util/types";
import { useAudio } from "@util/hooks";
import { useStore } from "@state/store";

interface Props extends PageProps {
  data: Query;
  pageContext: PageContext;
}

export default function PageTemplate({ data, pageContext }: Props) {
  if (!data.sanityPage) return null;

  const { blocks, backgroundOptions, backgroundColour, imageWithMeta, audio } = data.sanityPage;

  const { globalQuiz, setGlobalQuizz, setIsPreview, setGlobalQuizOutcomes } = useStore();

  const { sectionQuizzes } = pageContext;

  useEffect(() => {
    setIsPreview(false);
  }, []);

  useEffect(() => {
    if (sectionQuizzes && globalQuiz) {
      let globalQuizArray: any = [];
      let outcomesArray: any = [];

      sectionQuizzes.forEach(quiz => {
        if (globalQuiz == null) return;

        //Check for existing quiz
        const exists = globalQuiz && globalQuiz?.find(q => q.id === quiz._id);
        if (!exists) {
          const removeDrafts = quiz._id.replace("draft.", "");

          const globalForm = {
            id: removeDrafts,
            title: quiz.title,
            questions: [],
            initialScoreValue: quiz.initialScoreValue,
            scoringOption: quiz.scoringOption,
          };

          globalQuizArray.push(globalForm);
        }

        //save outcomes
        const outcomes = quiz.outcomes;
        if (outcomes) {
          const outcomeObj = {
            id: quiz._id,
            outcomes: outcomes,
          };
          outcomesArray.push(outcomeObj);
        }
      });
      if (globalQuizArray.length > 0) {
        setGlobalQuizz(globalQuizArray);
      }
      if (outcomesArray.length > 0) {
        setGlobalQuizOutcomes(outcomesArray);
      }
    }
  }, []);

  return (
    <Container
      display="flex"
      flexDirection="column"
      margin="50px auto"
      sanityBackgroundColor={
        backgroundOptions && backgroundOptions == "colour" && backgroundColour?.value
      }
      position="relative"
    >
      {backgroundOptions == "image" && imageWithMeta && <Image data={imageWithMeta} isBackground />}
      <div style={{ zIndex: 10, position: "relative" }}>
        {blocks &&
          blocks.map((component, index) => {
            if (component == null) return null;
            return <Component key={(component._type, index)} data={component} />;
          })}
      </div>
      {/* <FooterSections pageFooter={pageMeta.pageFooter} /> */}
    </Container>
  );
}

export const query = graphql`
  query PageQuery($slug: String) {
    sanityPage(slug: { current: { eq: $slug } }) {
      blocks {
        ...sanityColumnBlock
      }
      backgroundOptions
      backgroundColour {
        ...sanityColorlist
      }
      imageWithMeta {
        ...sanityImageFullWidthNoCompressionNoCompression
      }
    }
  }
`;
